/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react'
import fetch from 'isomorphic-fetch'
import { StaticQuery, graphql, navigate } from 'gatsby'
import ReCAPTCHA from 'react-google-recaptcha'
import { FaCircleNotch } from 'react-icons/fa'
import ls from 'local-storage'
import * as FormFields from './Fields'

import './leads.scss'

const recaptchaRef = React.createRef()
class Index extends Component {
  constructor(props) {
    const { data, id = null } = props
    const { siteSettings, allWordpressWpForm } = data
    let settings =
      id &&
      allWordpressWpForm &&
      allWordpressWpForm.nodes.filter(i => {
        return i.wordpress_id === parseInt(id, 10)
      })
    settings = settings?.[0]?.formJson || {}

    super(props)
    this.state = {
      error: null,
      loading: false,
      submitted: false,
      ready: false,
      isMailerLiteSubscribed: false,
      id,
      siteKey: siteSettings.options.googleSiteKey,
      settings: settings && settings.formFields && settings.formFields,
      lead: {
        location:
          typeof window !== `undefined` ? window.location.href : 'Server',
        recipientAddress:
          settings && settings.recipientAddress
            ? settings.recipientAddress
            : null,
        formId: id,
      },
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const recaptchaValue =
      recaptchaRef.current && recaptchaRef.current.getValue()
    const formReValue = e.target.elements['g-recaptcha-response'].value
    if (formReValue !== '' && recaptchaValue === formReValue) {
      this.captchaSubmit(e)
    }
  }

  checkCaptcha = () => {
    const recaptchaValue =
      recaptchaRef.current && recaptchaRef.current.getValue()
    if (recaptchaValue) {
      this.setState({ ready: true })
    } else {
      this.setState({ ready: false })
    }
  }

  captchaSubmit = async () => {
    this.setState({ loading: true })
    const leadFunctionUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/leads/v2/submit`
    const appSecret = process.env.GATSBY_WEB_APP_SECRET
    // Add recipient
    const { lead } = this.state
    const { thankYou } = this.props

    const mailerLiteSubscriber = {
      email: lead?.email || '',
      fields: {
        name: lead?.name,
        company: lead?.company,
        phone: lead?.phone,
      },
    }

    const tickedSubscription =
      lead?.['sign-up-to-our-newsletter'] === `yes` || false
    fetch(leadFunctionUrl, {
      method: 'POST',
      body: JSON.stringify({ secret: appSecret, lead }),
    })
      .then(response => {
        if (response.status === 200) {
          this.setState({ loading: false, submitted: true, lead: {} })
          if (!thankYou) {
            /** Add this lead to mailerlite subscription */
            if (tickedSubscription && mailerLiteSubscriber) {
              fetch('/.netlify/functions/mailer-lite', {
                method: 'POST',
                body: JSON.stringify(mailerLiteSubscriber),
              })
                .then(res => {
                  console.log(res)
                  this.setState({ isMailerLiteSubscribed: true })
                })
                .catch(err => {
                  console.log('Error with mailer lite function')
                  console.log(err)
                })
            }
          }
          if (thankYou && thankYou !== '') {
            /** Add this lead to mailerlite subscription */
            if (tickedSubscription && mailerLiteSubscriber) {
              fetch('/.netlify/functions/mailer-lite', {
                method: 'POST',
                body: JSON.stringify(mailerLiteSubscriber),
              })
                .then(res => {
                  console.log(res)
                  this.setState({
                    isMailerLiteSubscribed: true,
                  })
                  navigate(thankYou)
                })
                .catch(err => {
                  console.log('Error with mailer lite function')
                  console.log(err)
                })
            }
          }
        } else {
          this.setState({ loading: false, error: true, lead: {} })
        }
      })
      .catch(error => {
        console.error('submitForm Error', error)
        this.setState({ loading: false, error: true, lead: {} })
      })
  }

  // Form change
  handleChange(e) {
    const { lead } = this.state
    /*
     * build out our subscription details for email form subscriptions to mailerlite
     */

    // If file
    if (e.target.type === 'file') {
      const fileName = `lead-file-${e.target.name}`
      const file = e.target.files[0]
      lead[fileName] = { data: '', file: '' }

      if (file) {
        const reader = new FileReader(file)
        reader.readAsDataURL(file)
        reader.onload = () => {
          // set image and base64'd image data in component state
          lead[fileName].data = reader.result
          lead[fileName].file = file.name
        }
      }
    } else if (e.target.type === 'checkbox') {
      // Toggle value on off
      if (lead[e.target.name] === e.target.value) {
        lead[e.target.name] = ''
      } else {
        lead[e.target.name] = e.target.value
      }
    } else {
      // All other fields
      lead[e.target.name] = e.target.value
    }
    this.setState({ lead })
  }

  render() {
    const {
      loading,
      submitted,
      error,
      lead,
      siteKey,
      id,
      settings,
      ready,
      isMailerLiteSubscribed,
    } = this.state
    if (!id) return 'No form id!'

    if (!settings) return 'There is a problem with the JSON!'

    if (submitted)
      return (
        <div className="lead-form thank-you">
          <h3>Thank you!</h3>
          <p>Your message has been sent.</p>
        </div>
      )
    const formatComponentName = string => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    return (
      <div className="lead-form">
        {error && (
          <div className="error">
            <div>
              Unfortunately an error occurred. Please refresh the page and try
              again.
            </div>
          </div>
        )}
        {loading && (
          <div className="loading">
            <div>
              <FaCircleNotch className="spin" /> Loading...
            </div>
          </div>
        )}
        <form
          className="lead-form__inner"
          onSubmit={event => {
            this.handleSubmit(event)
          }}
        >
          <div className="form-inner">
            {settings.map((field, index) => {
              const FormComponent = FormFields[formatComponentName(field.type)]
              // Add quote to lead
              if (field.type === 'quoteList') {
                lead.quote = ls.get('quote')
              }
              return (
                <FormComponent
                  key={index}
                  field={field}
                  value={lead}
                  handleChange={e => this.handleChange(e)}
                />
              )
            })}
            <div className="captcha-wrapper">
              <button
                onFocus={() => this.checkCaptcha()}
                onMouseOver={() => this.checkCaptcha()}
                className={`submit${ready ? ' ready' : ' not-ready'}`}
                type="submit"
              >
                Submit
              </button>
              <ReCAPTCHA
                className="captcha"
                ref={recaptchaRef}
                sitekey={siteKey}
              />
            </div>
            {isMailerLiteSubscribed && (
              <p className="success">
                Thank you for subscribing to our newsletter!
              </p>
            )}
          </div>
        </form>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpSettings {
          title
          siteUrl
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            googleSiteKey
          }
        }
        allWordpressWpForm {
          nodes {
            wordpress_id
            formJson {
              recipientAddress
              formFields {
                type
                required
                placeholder
                options {
                  label
                  value
                }
                name
                className
                accept
              }
            }
          }
        }
      }
    `}
    render={data => <Index data={data} {...props} />}
  />
)
